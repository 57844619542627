/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import 'ngx-toastr/toastr';

.list-group-item {
  color: #000;
}

.input-group input::placeholder {
  color: #999;
  font-style: italic;
}

/* For Internet Explorer */
.input-group input:-ms-input-placeholder {
  color: #999;
  font-style: italic;
}

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}
.dp-picker-input {
  border: none!important;
}

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 70vw;
  font-weight: 200;
  height: 200px;
  border: 2px dashed #f16624;
  border-radius: 5px;
  background: white;
  margin: 20px;

  &.hovering {
    border: 2px solid #f16624;
    background-color: #0c5460;
    color: #dadada !important;
  }

  .file-label {
    font-size: 1.2em;
  }
}

progress::-webkit-progress-value {
  transition: width 0.1s ease;
}
.project-box {
  padding: 20px 10px;
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}


.nice-select {
  background-color: #f8f8f8 !important;
}
.home-form-btn {
  width: 100%;
  border: none;
  background-color: #ff7f00;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  height: 60px;
  letter-spacing: 2px;
  transition: all 0.3s ease-out 0s;
}
.apartment-book {
  width: auto;
  height: 40px;
  display: inline-block;
  margin: 20px;
  padding: 10px;
}
.apartment-book:hover{
  color: #ff7f00;
  background-color: #fff;
  border: 1px solid #ff7f00;
}

dp-day-calendar .dp-calendar-day, dp-calendar-nav .dp-nav-header-btn, dp-day-calendar .dp-calendar-weekday{
  background-color: #fff;
  color: #ff7f00;
  border: none;
}
dp-calendar-nav .dp-calendar-nav-container, dp-calendar-nav .dp-nav-date-btn {
  height: 35px !important;
  border-top: 1px solid #ff7f00!important;
  border-bottom: 1px solid #ff7f00!important;
  border-right: none!important;
  border-left: none!important;
}
dp-day-calendar .dp-calendar-day{
  width: 40px!important;
  height: 35px!important;
}

dp-day-calendar .dp-calendar-weekday {
  box-sizing: border-box;
  display: inline-block;
  width: 40px!important;
  text-align: center;
  border-left: none!important;
  border-bottom: 1px solid #ff7f00!important;
  color: #ff7f00;
  font-weight: bold;
}
dp-day-calendar .dp-calendar-wrapper {
  border-top-right-radius: 10px;
  border: 1px solid #fff !important;
  border-left: none!important;
  border-right: none!important;
}

dp-calendar-nav .dp-calendar-nav-left, dp-calendar-nav .dp-calendar-nav-right {
  width: 25px!important;
  height: 25px!important;
  background-color: #fff;
  color: #ff7f00;

}
.dp-nav-header-btn{
  padding: 2px 5px;
}
.ng-select.custom {
  border:0px;
  min-height: 0px;
  border-radius: 0;
}
.ng-select.custom .ng-select-container  {
  min-height: 0px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ff7f00;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{

  background-color: #ff7f00;
  color: #ffffff;
}
